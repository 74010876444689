import axios from "./axios";
// 发送手机验证码
export const sendMsgCode = (data) => {
  return axios.post("/captcha", data);
};
// 根据手机号验证码登录
export const phoneLogin = (data) => {
  return axios.post("/login", data);
};
// 生成订单
export const makeOrderId = (data) => {
  return axios.post("/add", data);
};
// 查看历史报告
export const queryHistory = (data) => {
  return axios.get("/list", { params: data });
};
// 导出PDF接口;
export const exportFile = (id) => {
  return axios({
    method: "get",
    url: "/export/" + id, //后端下载接口地址
    // responseType: "blob", // 设置接受的流格式
  });
};
// 个人风险报告查询结果
export const peopleResult = (data) => {
  return axios.get("/report", { params: data });
};
// 根据订单号查询支付相关信息
export const infoById = (data) => {
  return axios.get("/info", { params: data });
};
